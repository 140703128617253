import axios from 'axios'
import * as logging from './logging'

const Axios = axios.create({
  baseURL: '/am/',
})

Axios.interceptors.response.use(
  response => response,
  async error => {
    if (error.response.status !== 401) {
      try {
        await logging.error(error)
      } catch (e) {
        const consoleLog =
          process.env.NODE_ENV === 'production' ? console.error : console.warn
        consoleLog(
          `${e.response.status} when attempting to reach logging service`,
        )
      }
    }

    throw error
  },
)

export default Axios
