import axios from 'axios'

const Axios = axios.create()

type endpoint = 'debug' | 'info' | 'warn' | 'error'

const logUrl = '/am/am-logging-ws'

function postLog(endpoint: endpoint = 'info') {
  return async (data: unknown) => {
    try {
      return Axios.post(`${logUrl}/${endpoint}`, data)
    } catch (error) {
      console.error(`Unable to send ${endpoint} log to server`, data, error)
    }
  }
}

export const debug = postLog('debug')
export const info = postLog('info')
export const log = postLog('info')
export const warn = postLog('warn')
export const error = postLog('error')
