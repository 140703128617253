import { Errors } from '../types'
import Axios from './axios'
import { History } from 'history'
import { logout } from './auth'
import { ForwardUrl } from './fowardUrl'

export const isEulaRead = async (
  setErrorMessage?: (e: Errors) => void,
): Promise<boolean> => {
  try {
    const { data } = await Axios.get<{ read: boolean }>(
      'asset-manager-ws/eula/read',
    )

    return data.read
  } catch (e) {
    console.error(e)
    if (setErrorMessage) {
      checkEulaError(setErrorMessage)
    }

    return false
  }
}

export async function markEulaRead() {
  return Axios.post('asset-manager-ws/eula/read')
}

export async function handleForwarding(history: History) {
  const forwardUrl = ForwardUrl.get()
  ForwardUrl.clear()
  if (forwardUrl && forwardUrl !== '/') {
    window.location.href = forwardUrl
  } else {
    history.replace('/')
  }
}

async function checkEulaError(setErrorMessage: (e: Errors) => void) {
  try {
    await logout()
  } catch (e) {
    console.error(e)
  } finally {
    setErrorMessage(Errors.eulaDisagree)
  }
}

export const handleRedirects: (args: {
  history: History
  setErrorMessage: (e: Errors) => void
  setEulaRead: (b: boolean) => void
}) => void = async ({ history, setErrorMessage, setEulaRead }) => {
  const isRead = await isEulaRead(setErrorMessage)

  setEulaRead(isRead)

  if (isRead) {
    await handleForwarding(history)
  }
}
