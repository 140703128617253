export interface Permissions {
  id: number
  name: string
  displayName: string
  tool: {
    displayName: string
    id: number
    name: string
  }
}

export interface Tool {
  url: string
  svgResourceName: string
  internal: boolean
  description: string
  svg: string
  featuredSvg: string
  id: number
  displayName: string
  name: string
  displayValue: string
}

export interface VisibleTools {
  featured: Tool[]
  secondary: Tool[]
  internal: Tool[] | null
}

export enum Errors {
  authCheckFailed = 'Authentication check failed. Please contact Clearwater or try to log in later.',
  badLogin = 'Incorrect username or password. Please try again.',
  serverError = 'Server unable to process request. Please contact Clearwater or try again later.',
  eulaDisagree = 'You must agree to the EULA before proceeding. If you have questions or concerns, please contact your administrator.',
}

export type RequestedBoolean = null | boolean | 'failed'

export type EulaState = RequestedBoolean | { rejected: true }

export type MfaDetails =
  | {
      mfaRequired: true
      oktaBaseUrl: string
      oktaAppPath: string
      vendorId: string
    }
  | { mfaRequired: false }
