import Axios from './axios'
import { format as dateFormat } from 'date-fns'
import { RequestedBoolean, MfaDetails } from '../types'
import { History } from 'history'

type Login = {
  userName: string
  password: string
  privateLabelName?: string | null
}

export const login = async ({
  userName: username,
  password,
  privateLabelName: plName,
}: Login) => {
  return Axios.post<void>('auth/login/', {
    username,
    password,
    timeZoneOffset: dateFormat(new Date(), 'XXX'),
    plName: plName === '' ? undefined : plName,
  })
}

type Logout = {
  pl?: string
}

export async function logout(history?: History) {
  const { data } = await Axios.post<Logout>('auth/logout')

  history?.replace(`/login/${data.pl ?? ''}`)
}

export async function requestMfaDetails(
  rawPlName?: string,
): Promise<MfaDetails | Error> {
  let privateLabelName = rawPlName?.slice()

  try {
    const { data } = await Axios.get<MfaDetails>(`auth/mfaDetails`, {
      params: {
        privateLabelName,
      },
    })
    return data
  } catch (e) {
    const newError = new Error(e)
    console.error(newError)
    return newError
  }
}

export async function checkIfLoggedIn(): Promise<RequestedBoolean> {
  let responseCode: number | undefined

  try {
    const result = await Axios.get('auth/check')

    responseCode = result.status
  } catch (error) {
    responseCode = error.response.status
  }

  switch (responseCode) {
    case 204:
    case 401: {
      return responseCode === 204
    }
    // If the request doesn't terminate
    case undefined:
    default: {
      return 'failed'
    }
  }
}

;(window as any).logout = logout
