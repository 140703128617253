import React from 'react'
import { HeaderService as PortalHeader } from '@northfork/header'

type Effect = (() => Promise<void>) | (() => void)

export const useOnce = (f: Effect) => {
  React.useEffect(() => {
    f()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export const useAsyncEffect = (f: Effect, deps?: unknown[]) => {
  React.useEffect(() => {
    f()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps ?? [])
}

let headerLoadedOnce = false

export const usePortalHeader = (cb?: () => void) => {
  useOnce(() => {
    if (!headerLoadedOnce) {
      headerLoadedOnce = true
      const script = document.createElement('script')
      script.onload = function() {
        PortalHeader.renderHeader()
        if (cb != null) {
          cb()
        }
      }
      script.async = true
      script.src = '/am-header/header.js'

      document.head.appendChild(script)
    }
  })
}
