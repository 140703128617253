// https://localhost/login/Analytics?forward=https://chickenonaraft.com

/* Forward URL */
function getForwardUrl() {
  const forward = new URLSearchParams(window.location.search).get('forward')
  const { hash } = window.location

  const maybeForward = forward ? `${forward}${hash}` : forward

  return isValidForwardUrl(maybeForward) ? maybeForward : null
}

const FORWARD_URL_KEY = 'orofino-landing-forward-url'

export const ForwardUrl = {
  setOnce() {
    if (window.location.pathname === '/') {
      return
    }

    const forwardUrl = getForwardUrl()

    if (forwardUrl == null) {
      ForwardUrl.clear()
      return
    }

    localStorage.setItem(FORWARD_URL_KEY, forwardUrl)
  },
  get() {
    return localStorage.getItem(FORWARD_URL_KEY)
  },
  clear() {
    if (localStorage.getItem(FORWARD_URL_KEY)) {
      localStorage.removeItem(FORWARD_URL_KEY)
    }
  },
}

//NFORK-1749: Forward URL must be a relative path, should start with a `/` and not contain any '..'
function isValidForwardUrl(url: string | null) {
  if (!url) {
    return false
  }

  return !url.includes('login') && url.match(/^\/[^/\\]/) && !url.includes('..')
}
/* End Forward URL */
