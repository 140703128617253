import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import * as FS from '@fullstory/browser'

/**
 * Initialize FullStory user analytics tool.
 * Note that the user is anonymous here, and will be identified once logged in by am-header, which is loaded by the landing page after login.
 */
FS.init({
    orgId: 'V1QFC',
    namespace: 'FS',
    host: 'fullstory.com',
  })


ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
