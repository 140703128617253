import React from 'react'
import { usePortalHeader } from '../hooks'
import { Card } from 'react-bootstrap'
import './ErrorPage.scss'
import { ReactComponent as Icon404 } from '../404/404.svg'
import { Errors } from '../types'

const ErrorPage: React.FunctionComponent<{}> = () => {
  const [loadedHeader, setLoadedHeader] = React.useState(false)
  usePortalHeader(() => setLoadedHeader(true))

  if (!loadedHeader) {
    return null
  }

  return (
    <div className="page--404">
      <Card>
        <Card.Body>
          <Icon404 className="not-found-icon" />
          <div className="title">Authentication Check Failed</div>
          <div className="message">{Errors.authCheckFailed}</div>
        </Card.Body>
      </Card>
    </div>
  )
}

export default ErrorPage
