import React, { Suspense, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
  Redirect,
  RouteProps,
} from 'react-router-dom'
import './App.scss'
import { useOnce, useAsyncEffect } from './hooks'
import * as login from './requests/auth'
import * as eula from './requests/eula'
import { ForwardUrl } from './requests/fowardUrl'
import { RequestedBoolean, Errors, MfaDetails, EulaState } from './types'
import ErrorPage from './ErrorPage/ErrorPage'

const Login = React.lazy(async () => import('./Login/Login'))
const Okta = React.lazy(async () => import('./Login/OktaWrapper'))

const Eula = React.lazy(async () => import('./eula/StaticEula'))

const Landing = React.lazy(async () => import('./landing/Landing'))
const Route404 = React.lazy(async () => import('./404/404'))

type State = {
  isLoggedIn: RequestedBoolean
  isEulaRead: RequestedBoolean | { rejected: true }
  privateLabelName: string | { neverSet: true } | null
  mfaDetails: MfaDetails | null
  criticalRequestsUnreachable: string | null
}

const initialState = {
  isLoggedIn: null,
  isEulaRead: null,
  privateLabelName: null,
  mfaDetails: null,
  criticalRequestsUnreachable: null,
}

type Action =
  | {
      type: 'SET_FIELD'
      key: keyof State
      value: State[keyof State]
    }
  | {
      type: 'SET_MULTIPLE_FIELDS'
      fields: Partial<State>
    }

const reducer = (state: State, action: Action): State => {
  if (process.env.NODE_ENV !== 'production') {
    console.group('Update')
    console.log(action)
    console.log(state)
    console.groupEnd()
  }

  switch (action.type) {
    case 'SET_FIELD':
      return {
        ...state,
        [action.key]: action.value,
      }
    case 'SET_MULTIPLE_FIELDS':
      return {
        ...state,
        ...action.fields,
      }
  }
}

// https://stackoverflow.com/a/43171515/1924257
const PrivateRoute: React.FunctionComponent<{
  isLoggedIn: RequestedBoolean
} & RouteProps> = props => {
  const { isLoggedIn, ...routeProps } = props

  return (
    <Route
      {...routeProps}
      render={props => {
        if (isLoggedIn === true) {
          return routeProps.render!(props)
        }

        return (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }}
    />
  )
}

function LazyRoutes() {
  const [state, dispatch] = React.useReducer(reducer, initialState),
    { isLoggedIn, isEulaRead, mfaDetails, privateLabelName } = state,
    history = useHistory()

  useOnce(ForwardUrl.setOnce)

  useEffect(() => {
    const pathname = history.location.pathname,
      plPattern = /^\/login\/([^/]*)\/?$/g,
      plMatches = plPattern.exec(pathname),
      justLoginPath = /^\/login\/?$/.test(pathname)

    let privateLabelName = plMatches ? plMatches[1] : null

    if (justLoginPath) {
      // Empty string will trigger a request to the back end unlike null or {neverSet: true}
      privateLabelName = ''
    } else if (privateLabelName == null) {
      dispatch({
        type: 'SET_FIELD',
        key: 'privateLabelName',
        value: { neverSet: true },
      })

      return
    }

    dispatch({
      type: 'SET_MULTIPLE_FIELDS',
      fields: {
        privateLabelName,
      },
    })
  }, [history.location.pathname])

  useAsyncEffect(
    async function grabMfaDetails() {
      if (privateLabelName == null || typeof privateLabelName === 'object') {
        return
      }

      const maybeMfaDetails = await login.requestMfaDetails(privateLabelName)

      if (maybeMfaDetails instanceof Error) {
        dispatch({
          type: 'SET_MULTIPLE_FIELDS',
          fields: {
            criticalRequestsUnreachable: Errors.authCheckFailed,
            mfaDetails: { mfaRequired: false },
          },
        })
      } else {
        dispatch({
          type: 'SET_MULTIPLE_FIELDS',
          fields: {
            mfaDetails: maybeMfaDetails,
          },
        })
      }
    },
    [privateLabelName],
  )

  useOnce(async () => {
    const nextPage = await (async () => {
      const isLoggedIn = await login.checkIfLoggedIn()
      const { pathname, search: queryParams } = history.location

      dispatch({
        type: 'SET_FIELD',
        key: 'isLoggedIn',
        value: isLoggedIn,
      })

      switch (isLoggedIn) {
        case 'failed': {
          dispatch({
            type: 'SET_FIELD',
            key: 'criticalRequestsUnreachable',
            value: Errors.authCheckFailed,
          })
          return `${pathname}${queryParams}`
        }
        case null:
        case false: {
          if (pathname.startsWith('/login')) {
            return `${pathname}${queryParams}`
          }

          return '/login'
        }
        case true: {
          break
        }
      }

      const eulaRead = await eula.isEulaRead()

      dispatch({
        type: 'SET_FIELD',
        key: 'isEulaRead',
        value: eulaRead,
      })

      if (!eulaRead) {
        return '/login'
      }

      if (history.location.pathname.startsWith('/login')) {
        return '/'
      }

      const forwardUrl = ForwardUrl.get()

      if (forwardUrl != null) {
        ForwardUrl.clear()

        return forwardUrl
      }

      return history.location.pathname
    })()

    history.replace(nextPage)
  })

  switch (isLoggedIn) {
    case null:
      return null
    case false:
    case true:
      break
    default:
    case 'failed':
      return <ErrorPage />
  }

  return (
    <Suspense fallback={null}>
      <Switch>
        <Route
          path="/login"
          render={({ history }) => {
            if (mfaDetails === null) {
              return null
            }

            const setLoggedIn = (value: RequestedBoolean) =>
              dispatch({
                type: 'SET_FIELD',
                key: 'isLoggedIn',
                value,
              })

            const setEulaRead = (value: EulaState) =>
              dispatch({
                type: 'SET_FIELD',
                key: 'isEulaRead',
                value,
              })

            if (mfaDetails?.mfaRequired) {
              return (
                <Okta
                  isLoggedIn={isLoggedIn}
                  setLoggedIn={setLoggedIn}
                  setEulaRead={setEulaRead}
                  history={history}
                  isEulaRead={isEulaRead}
                  mfaDetails={mfaDetails}
                />
              )
            }

            const normalizedPrivateLabel =
              typeof privateLabelName === 'object'
                ? null
                : privateLabelName

            return (
              <Login
                history={history}
                isLoggedIn={isLoggedIn}
                isEulaRead={isEulaRead}
                privateLabelName={normalizedPrivateLabel}
                setEulaRead={setEulaRead}
                setLoggedIn={setLoggedIn}
              />
            )
          }}
        />
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          exact
          path="/eula"
          component={Eula}
        />
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          render={() => <Landing />}
          exact
          path="/"
        />
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          exact
          render={({ history }) => <Route404 history={history} />}
        />
      </Switch>
    </Suspense>
  )
}

function App() {
  return (
    <div className="app-body">
      <Router>
        <LazyRoutes />
      </Router>
    </div>
  )
}

export default App
